@font-face {
  font-family: "PPNeueMontreal-Bold";
  src: url("./fonts/PPNeueMontreal-Bold.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal-Book";
  src: url("./fonts/PPNeueMontreal-Book.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal-Italic";
  src: url("./fonts/PPNeueMontreal-Italic.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("./fonts/PPNeueMontreal-Medium.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal-SemiBoldItalic";
  src: url("./fonts/PPNeueMontreal-SemiBolditalic.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal-Thin";
  src: url("./fonts/PPNeueMontreal-Thin.otf") format("otf");
}

/*General Styles*/
.font-bold {
  font-family: "PPNeueMontreal-Bold", sans-serif;
  font-weight: normal;
}
.font-book {
  font-family: "PPNeueMontreal-Book", sans-serif;
  font-weight: normal;
}
.font-italic {
  font-family: "PPNeueMontreal-Italic", sans-serif;
  font-weight: normal;
}
.font-medium {
  font-family: "PPNeueMontreal-Medium", sans-serif;
  font-weight: normal;
}
.font-sbi {
  font-family: "PPNeueMontreal-SemiBolditalic", sans-serif;
  font-weight: normal;
}
.font-thin {
  font-family: "PPNeueMontreal-Thin", sans-serif;
  font-weight: normal;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
.line {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #2b2a29;
  align-items: center;
  margin: auto;
}
.line.first {
  border-top: 1px solid #2b2a29;
}
.line .font-medium {
  text-align: right;
}
.dark {
  color: #2b2a29;
}
.light {
  color: #f6f6f8;
}
a {
  text-decoration: none;
}
.bg-beige {
  background-color: #f0efeb;
  color: #2b2a29;
}
.bg-dark {
  background-color: #2b2a29;
  color: #f6f6f8;
}
.bg-gray {
  background-color: #cccfd2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
}

.gray-button {
  border: 1px solid #2b2a29;
  padding: 1rem;
  width: 15rem;
  margin: auto;
}

.img-center {
  width: 100%;
}
.container {
  padding: 0 5rem 3rem 5rem;
  margin: auto;
}

.banner {
  max-width: 100%;
  padding-bottom: 0.3rem;
  width: 100%;
}
.two-col {
  display: flex;
  padding: 0rem 10rem 3rem 10rem;
  align-items: center;
  justify-content: space-between;
}
.two-col div {
  width: 40%;
}
.two-col img {
  width: 80%;
}

.section-title {
  padding: 0 0 0 3rem;
  width: 20%;
  padding-top: 2rem;
}

.specs {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}
/*Mockup Styles*/
.banner-title {
  position: relative;
  top: -84px;
  z-index: 0;
  width: 100%;
}
.footer-video {
  padding-top: 3rem;
}

.ot-col .font-book {
  text-align: justify;
}
.onecol .title {
  margin: 2rem 0;
}

.serviceList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;
}

.contacto .container {
  padding-top: 2rem;
}

.contacto .img {
  padding: 2rem 0 4rem 0;
}

/*#EFEEEA*/

form input::placeholder,
form textarea::placeholder,
select::placeholder {
  font-family: "PPNeueMontreal-Thin", sans-serif;
  color: #f6f6f8;
  padding: 0 0 0.3rem 0;
}
select option {
  font-family: "PPNeueMontreal-Thin", sans-serif;
  color: #f6f6f8;
  padding: 0 0 0.3rem 0;
}
select {
  padding: 0 0 0.3rem 0;
  font-family: "PPNeueMontreal-Thin", sans-serif;
  margin: 0 0 0.3rem 0;
}

.formContainer .btn {
  border-bottom: 1px solid #f6f6f8;
  display: flex;
  align-items: center;
  padding: 0.3rem;
}
.bg-dark .quote {
  padding: 2rem 0;
}
.projects {
  padding-bottom: 3rem;
}
.projectBanner {
  width: 90%;
  margin: auto;
}
.formContainer button {
  width: 5.5rem;
  background-color: transparent;
  border: none;
  text-align: left;
  color: #f6f6f6;
  padding-left: 0;
  cursor: pointer;
}
form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #f6f6f8;
}
form textarea {
  border-bottom: 1px solid #f6f6f8;
}
form input,
form textarea {
  background: transparent;
  font-family: "PPNeueMontreal-Thin", sans-serif;
  font-weight: normal;
  color: #f6f6f8;
  padding: 0.3rem 0;
  margin-bottom: 0.3rem;
}
form label {
  padding-top: 0.3rem;
  margin-top: 0.3rem;
}
select {
  background: transparent;
  border: none;
  color: #f6f6f8;
  width: 87%;
  border-bottom: 1px solid #f6f6f8;
}
.sliderGp {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container-slider {
  width: 100%;
  display: flex;
  z-index: 0;
  overflow: hidden;
}
.sliderCtrl {
  display: flex;
  justify-content: center;
}
.sliderCtrl p {
  margin: 0 2rem;
  color: #f6f6f6;
  position: relative;
  bottom: 4rem;
  cursor: pointer;
}
.slider {
  display: flex;
}
.slide-container {
  display: flex;
  min-width: 100%;
}
.slide-container img {
  width: 100%;
}
.proyecto {
  max-width: 100%;
}

/*Correct Styles*/
/*Desktop Styles*/

@media (min-width: 911px) {
  .bg-transparent {
    background-color: transparent;
  }
  .bigContainers {
    padding: 5rem !important;
  }
  .home .bigContainers {
    padding: 5rem 5rem 0 !important;
  }
  .containers {
    padding: 5rem 10rem !important;
  }
  .mid-containers {
    padding: 0 10rem !important;
  }
  .desktop {
    display: block !important;
  }
  .mobile {
    display: none !important;
  }
  .paragraph {
    font-size: 1.3rem !important;
  }
  .container-2 {
    align-items: flex-end;
  }
  .container-3,
  .container-6 {
    display: flex;
    justify-content: space-between;
  }
  .container-6 .col {
    width: 45%;
  }
  .container-3 p:not(.full),
  .container-3 div:not(.full) {
    width: 45%;
  }
  .container-2 div,
  .container-3 div {
    width: 60%;
  }
  .footer-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1rem;
  }
  .footer-date,
  .title {
    font-size: 3rem !important;
  }
  .formContainer form {
    flex-wrap: nowrap !important;
    align-items: center;
  }
  .formContainer,
  .contact-info {
    font-size: 2rem !important;
  }
  .col-img {
    display: flex;
  }
  .quote {
    font-size: 3rem !important;
  }
  .quote .big-colon {
    font-size: 8rem !important;
  }
  .subtitle {
    font-size: 3rem !important;
  }
  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
  }
  .col2 {
    display: flex;
    justify-content: space-around;
    width: 70%;
  }
  .projects {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .midcol {
    width: 35%;
    padding: 0.5rem;
  }
  .fullcol {
    width: 55%;
    padding: 0.5rem;
  }
  .threecol {
    display: flex;
    padding: 5rem 0 3rem 0;
    align-items: center;
    flex-wrap: wrap;
  }
  .threecol div {
    width: 25%;
    padding: 1.5rem;
  }

  .home .container-3 {
    align-items: center !important;
  }
  .home .container-3 p {
    width: 100% !important;
    padding-right: 3rem;
  }
  .home .container-2 .container-3 .paragraph {
    width: 45% !important;
  }
  .home .quote {
    font-size: 1.7rem !important;
    width: 60%;
    text-align: center;
  }
  .home .big-colon {
    font-size: 5rem !important;
  }
  .bg-dark .quote {
    width: 100%;
  }
  .doubleBanner {
    width: 90%;
    margin: auto;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .doubleBanner div:not(.line) {
    width: 45%;
  }
  .project-st {
    font-size: 2rem;
  }
  .project-ph {
    font-size: 1.3rem;
  }
  .project-title,
  .link-sub {
    font-size: 2rem;
  }
  .menuBar {
    width: 50%;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
  }
  .lang {
    padding: 1rem;
    font-size: 0.8rem;
  }
  .serviceList {
    font-size: 1rem;
    width: 100%!important;
  }
.list {
  width: 80%!important;
}
}
.list {
  border-bottom: 1px solid #2b2a29;
}
.langChange {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  position: relative;
  padding: 1rem 3rem;
}

.logo {
  height: 1.5rem;
  padding: 1rem;
}
.mobile .header .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mobile .menu {
  position: fixed;
  top: -100vh;
  left: 0;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  transition: 1s;
  padding: 0;
  background-color: #f0efeb;
}

.mobile .header .flex img {
  padding: 1rem;
}
.mobile .responsive_nav {
  transform: translateY(110vh);
}
.mobile .header .menuBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mobile .header .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile .header .menuBar span {
  padding: 1rem;
  font-size: 1.5rem;
}
.mobile .header .lang {
  padding-top: 3rem;
}
/*General Mobile*/
.paragraph {
  text-align: justify;
}
#contact {
  padding-top: 2rem;
}
.info-description {
  font-family: "PPNeueMontreal-Book", sans-serif;
  font-weight: normal;
}
a {
  color: #2b2a29;
}
.desktop {
  display: none;
}
.mobile {
  display: block;
}
textarea {
  border: 1px solid #f6f6f8;
  padding: 1rem;
  width: 100%;
}
.title {
  font-size: 1.5rem;
}
.subtitle {
  font-size: 2rem;
  border-bottom: 1px solid #2b2a29;
  margin: 0 auto;
  padding-bottom: 1rem;
}
.quote {
  font-size: 1.5rem;
  margin: 0;
}
.big-colon {
  font-size: 3rem;
  line-height: 0rem;
}
Link {
  cursor: pointer;
}
/*Footer Styles*/
.containers,
.bigContainers {
  padding: 3rem;
}
.mid-containers,
.home .bigContainers {
  padding: 0 3rem;
}
.footer-date {
  border-bottom: 1px solid #f6f6f8;
  font-size: 1.5rem;
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.footerCol {
  margin: 3rem 0;
}
.footer .logo {
  width: 15rem;
  height: auto;
  padding-left: 0;
  padding-top: 0;
}
.copy {
  width: 100%;
}
.socials a {
  margin: 0.5rem;
}
/*Contact Form 2*/
.contact-info {
  font-size: 1rem;
}
.formContainer form {
  display: flex;
  flex-wrap: wrap;
}
.inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.inputs div:not(.input-service, .fillin) {
  margin: 1.5rem 1.5rem 1.5rem 0;
}
.fillin {
  display: flex;
}
.message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
/*Container 2*/
.container-2 {
  display: flex;
  flex-direction: column;
}
/*Container 5*/
.info {
  display: flex;
  flex-direction: column-reverse;
}
.container-5 {
  padding-bottom: 2rem;
}
/*Projects*/
.project {
  width: 100%;
}
.subtitle-link {
  border-bottom: 1px solid #2b2a29;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.subtitle-link span {
  padding-bottom: 0.3rem;
}
.allprojects {
  margin-top: 0;
  padding-top: 1rem;
}
/*Home*/
.threecol div {
  padding: 1.5rem;
}
.numbers {
  border-bottom: 1px solid #2b2a29;
  margin: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 2.5rem;
}
.padding-bottom {
  padding-bottom: 2rem;
}

/*Projects*/
.doubleBanner img {
  max-width: 100%;
}
.doubleBanner div:not(.line) {
  padding: 2rem 0;
}
.related {
  padding: 1rem 0;
}
.next {
  float: right;
}
.rotation {
  transform: rotate(90deg);
}
/*NavBar*/

/*

*/
